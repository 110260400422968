import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const image_w = 800
const image_h = 500

const MeterMountsImage = (props) => (
  <StaticImage
    src="../../../images/cyble-mounts-marked.jpeg"
    alt=""
    className="d-block w-100"
    objectFit="fixed"
    layout="fullWidth"
    transformOptions={{fit: "contain"}}
    backgroundColor="white"
    aspectRatio={image_w / image_h}
    width={image_w}
    height={image_h}
  />
)

export default MeterMountsImage
