import Layout from "../../../../src/components/layout";
import Seo from "../../../../src/components/seo";
import { StaticImage } from "gatsby-plugin-image";
import AboutImages from "../../../../src/components/AboutImages";
import * as React from 'react';
export default {
  Layout,
  Seo,
  StaticImage,
  AboutImages,
  React
};