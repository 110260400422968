import { StaticImage } from "gatsby-plugin-image";
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import Layout from "../../../../src/components/layout";
import Seo from "../../../../src/components/seo";
import ImagesTop from "../../../../src/pages/platform/huey-mobile/ImagesTop";
import * as React from 'react';
export default {
  StaticImage,
  getImage,
  GatsbyImage,
  Layout,
  Seo,
  ImagesTop,
  React
};