import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
//import { useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"

const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: "nav-link active" } : {className: "nav-link"}
}

const ExactNavLink = props => (
  <Link getProps={isActive} {...props} />
)

const Navbar = ({ siteTitle }) => {
  return (
    <nav className="navbar navbar-expand-md navbar-dark">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand" href="#">
          <StaticImage src="../images/huey-logo-white-transparent-800.png" height={50} alt="huey" />
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main-navbar"
                aria-controls="main-navbar" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="main-navbar">
          <ul className="navbar-nav me-auto mb-2 mb-md-0">
            <li className="nav-item">
              <ExactNavLink to="/">Home</ExactNavLink>
            </li>
            <li className="nav-item">
              <ExactNavLink to="/products">Products</ExactNavLink>
            </li>
            {/*<li className="nav-item">*/}
            {/*  <ExactNavLink to="/platform">Platform</ExactNavLink>*/}
            {/*</li>*/}
            {/*<li className="nav-item">*/}
            {/*  <ExactNavLink to="/about">About</ExactNavLink>*/}
            {/*</li>*/}
            <li className="nav-item">
              <ExactNavLink to="/team">Team</ExactNavLink>
            </li>
            <li className="nav-item">
              <ExactNavLink to="/contact">Contact</ExactNavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

Navbar.propTypes = {
  siteTitle: PropTypes.string,
}

Navbar.defaultProps = {
  siteTitle: ``,
}

export default Navbar
