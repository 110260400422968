import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
//import { useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"

const img_height = 200;

const AboutImages = (props) => {
  return (
    <div className="row">
      <div className="col-md">
        <div className="mt-3 mb-3">
          <StaticImage src="../images/huey-h2o-on-meter-800.jpeg" height={img_height} alt="huey h2o"/>
        </div>
      </div>
      <div className="col-md">
        <div className="mt-3 mb-3">
          <StaticImage src="../images/huey-mobile-43-800.jpeg" height={img_height} alt="huey mobile"/>
        </div>
      </div>
      <div className="col-md">
        <div className="mt-3 mb-3">
          <StaticImage src="../images/huey-home-43-800.jpeg" height={img_height} alt="huey home"/>
        </div>
      </div>
    </div>
  )
}

export default AboutImages;

