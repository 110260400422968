import { StaticImage } from "gatsby-plugin-image";
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import Layout from "../../../../src/components/layout";
import Seo from "../../../../src/components/seo";
import MeterMountsImage from "../../../../src/pages/products/huey-h2o/MeterMountsImage";
import * as React from 'react';
export default {
  StaticImage,
  getImage,
  GatsbyImage,
  Layout,
  Seo,
  MeterMountsImage,
  React
};