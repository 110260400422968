import Layout from "../../../../src/components/layout";
import Seo from "../../../../src/components/seo";
import { StaticImage } from "gatsby-plugin-image";
import Obfuscate from 'react-obfuscate';
import * as React from 'react';
export default {
  Layout,
  Seo,
  StaticImage,
  Obfuscate,
  React
};